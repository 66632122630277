@font-face {
  font-family: Nunito;
  src: url('./fonts/Nunito-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: Amita;
  src: url('./fonts/Amita-Regular.ttf');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: Amita;
  src: url('./fonts/Amita-Bold.ttf');
  font-weight: bold;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: 'Nunito', 'Arial', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #1e5799 url('./images/pattern-colorful.png') fixed repeat center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.customizeWindow {
  min-width: 300px;
  width: 100%;
  max-width: 480px;
  /* margin: 30px auto; */
  border: 5px solid #103f79;
  border-radius: 15px;
  overflow: hidden;
  background: whitesmoke;
  z-index: 9999;
}

.customizeHeader {
  padding: 10px 15px;
  width: 100%;
  background: #91cdf1;
  border-bottom: 5px solid #103f79;
  position: relative;
}
  .customizeHeader h1 {
    margin: 0;
    font-size: 18pt;
    display: inline-flex;
    align-items: center;
    color: #103f79;
  }

  .customizeHeader h1 > svg {
    margin-right: 5px;
  }

.customizePreview {
  position: relative;
  background-color: whitesmoke;
  background: url('./images/preview_background.jpg') no-repeat bottom center;
  background-size: cover;
  text-align: center;

  /* background-image: linear-gradient(#aae0ff .1em, transparent 1px), linear-gradient(90deg, #aae0ff 1px, transparent .1px);
  background-size: 1em 1em; */
}

  .customizePreview .popup-buttons {
    position: absolute;
    top: 0px;
    right: 0;
    padding-right: 10px;
  }

  .btn {
    font-family: 'Nunito', 'Arial', 'Helvetica', sans-serif;
    border: none;
    padding: 5px 10px;
    margin: 5px;
    background: black;
    color: white;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
  }

  .btn.popup {
    margin: 0;
    margin-left: 3px;
    padding: 10px 10px 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #103f79;
    color: #b3daf3;
    font-size: 10pt;
    line-height: 10pt;
  }

  .btn.popup:hover {
    color: white;
  }

  .customizePreview > .snowglobe {
    max-width: 100%;
    width: 360px;
    height: 360px;
    margin: auto;
    position: relative;
  }

  .customizePreview > .snowglobe > .layer {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:  center;
    top: 0;
    left: 0;
  }

  .customizePreview > .snowglobe > .layer.charas {
    position: absolute;
    width: 100%;
    height: auto;
    top: auto;
    left: 0;
    text-align: center;
    bottom: 125px;
  }

  .customizePreview > .snowglobe > .layer.charas > img {
    margin-left: -8px;
    margin-right: -8px;
  }

  .customizePreview > .snowglobe > .layer.captionText {
    width: 100%;
    height: 35px;
    top: auto;
    bottom: 70px;
    left: 0;
    text-align: center;
  }

  .customizePreview > .snowglobe > .layer.captionText > span.captionText {
    font-family: 'Amita', 'Monotype Corsiva', 'Garamond', serif;
    font-weight: bold;
    color: #4d4d54;
    font-size: 10pt;
    line-height: 35px;
    display: inline-block;
    width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-shadow: 1px 1px white;
  }

  .customizeControl {
    text-align: center;
    font-size: 10pt;
    padding: 15px 15px;
    width: 100%;
    background: #dee9f5;
    border-top: 5px solid #103f79;
  }

  .customizeControl form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

    .controlGroup {
      display: block;
      width: 50%;
      margin: 5px 0;
      padding: 0 5px;
    }

    .controlGroup .layerName {
      font-size: 11pt;
      margin: 0 0 2px;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      color:rgb(2, 2, 100);
    }

    .controlGroup .layerName svg {
      margin: 0 3px;
    }

    .radioGroup .radioOption {
      display: inline-block;
    }

    .controlGroup input[type="radio"] {
      display: none;
    }

    .controlGroup input[type="radio"] + label {
      display: inline-block;
      width: 40px;
      height: 40px;
      padding: 3px; 
      overflow: hidden;
      background: white;
      border: 3px solid #FEFEFE;
      border-radius: 5px;
      margin: 2px 3px;
    }

    .controlGroup input[type="radio"] + label img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }

    .controlGroup input[type="radio"]:checked + label {
      border-color: #b4163d;
    }

    .controlGroup select {
      width: 100%;
      background: white;
      height: 30px;
      border: 2px solid #fefefe;
      border-radius: 5px;
      margin: 3px 0;
      font-size: 11pt;
      font-family: 'Nunito', Arial, Helvetica, sans-serif;
      font-weight: bold;
    }
    
    .controlGroup input[type="text"],
    .controlGroup textarea {
      font-family: 'Nunito', Arial, Helvetica, sans-serif;
      font-weight: bold;
      width: 100%;
      background: white;
      border: 2px solid #fefefe;
      border-radius: 5px;
      margin: 2px;
    }

    .controlGroup .control_checkbox {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: center;
      font-size: 11pt;
      margin: 3px 0;
    }

    .controlGroup input[type="checkbox"] {
      margin: 2px;
    }

    .controlGroup textarea {
      font-family: 'Nunito', Arial, Helvetica, sans-serif;
      font-weight: bold;
      font-size: 13pt;
      height: 35px;
    }

    .controlGroup button {
      font-family: 'Nunito', 'Arial', 'Helvetica', sans-serif;
      display: inline-flex;
      padding: 10px 30px;
      margin: 5px 5px 0; 
      background: #8fc3ff;
      font-size: 12pt;
      font-weight: bold;
      border-radius: 8px;
      color: rgb(2, 2, 100);
      border: none;
      border-right: 2px solid #5594db;
      border-bottom: 2px solid #5594db;
    }


    .controlGroup button.red {
      background: #ffade6;
      color: rgb(100, 2, 43);
      border-right: 2px solid #db55a3;
      border-bottom: 2px solid #db55a3;
    }

    .controlGroup button:hover {
      background: #add3ff;
    }

      .controlGroup button.red:hover {
        background: #ffc5ee;
      }

    .controlGroup button:active,
    .controlGroup button.red:active {
      background: #fff3c0;
      color: #640217;
    }

    .controlGroup button > svg  {
      margin-right: 5px;
    }

.customizeFooter {
  /* text-align: center; */
    font-size: 10pt;
    padding: 10px 10px 5px;
    width: 100%;
    background: #103f79;
    border-top: 5px solid #103f79;
    color: #91cdf1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.customizeFooter  .footerLeft {
  text-align: left;
}
.customizeFooter .footerLeft a {
  display: inline-flex;
  align-items: center;
  margin: 0 5px;
  color: #abd5f0;
}

.customizeFooter .footerLeft a > svg {
  margin-right: 5px;
}
  .customizeFooter .footerLeft a:hover {
    color: #ffff91;
  }

 .customizeFooter  .footerRight {
   text-align: right;
 }

.popUp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: auto;
  position: absolute;
  top: 0;
  display: none;
  background: rgba(0,0,0,0.5) fixed;
}

.popUp:target {
  display: flex;
  z-index: 9999;
}

.popUp>.popUp_box {
  display: block;
  width: 350px;
  min-height: 100px;
  max-height: 90%;
  height: auto;
  padding: 20px;
  background: white;
  /* border: 5px solid red; */
  border-radius: 10px;
  text-align: center; 
  transition: all 1s;
}

.popUp_box .btn.close {
  background: #103f79;
  font-size: 9pt;
}

.popUp_box .btn.close:hover {
  background: #1959a7
}

.popUp_box .title {
  font-size: 14pt;
  margin: 0 auto 10px;
  text-align: center;
}
.popUp_box .content {
  text-align: justify;
  margin-bottom: 10px;
  font-size: 9pt;
}

.popUp_box .content p {
  margin: 7px auto;
}

.popUp_box .content hr {
  border: none;
  border-bottom: 1px solid lightgray;
}
.popUp_box .content a {
  color: rgb(107, 12, 36);
}
.popUp_box .content a:hover {
  color: rgb(179, 28, 66);
}